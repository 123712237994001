@charset "utf-8";

@use 'sass:math';

// Define defaults for each variable.

$base-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

$text-color:       #333 !default;
$background-color: #fdfdfd !default;
$head-background-color: rgba(0,0,0,0.8) !default;
$brand-color:      #2a7ae2 !default;

$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

//Aero Blue
$blue-color:  #C9FFE5 !default;
$blue-color-light: lighten($blue-color, 40%) !default;
$blue-color-dark:  darken($blue-color, 25%) !default;

//Baker-Miller Pink
$pink-color:  #FF91AF !default;
$pink-color-light: lighten($pink-color, 40%) !default;
$pink-color-dark:  darken($pink-color, 25%) !default;

// Width of the content area
$content-width:    800px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;
//
$navicon-size: 36px;
$navline-size: calc($navicon-size/1.5);
$navicon-color: white;
// $navicon-color-before: rgba(255,0,0,0.8);
// $navicon-color-after: rgba(0,0,255,0.8);
$navicon-color-before: $navicon-color;
$navicon-color-after: $navicon-color;
$navicon-transition: .3s;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "ishx-theme-awesome/base",
  "ishx-theme-awesome/layout",
  "ishx-theme-awesome/syntax-highlighting"
;
