/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1;

  @include media-query($on-laptop) {
    @include relative-font-size(2.25);
  }
}

.post-content {
  margin-bottom: $spacing-unit;
  text-align: justify;

  h2 {
    @include relative-font-size(2);

    @include media-query($on-laptop) {
      @include relative-font-size(1.75);
    }
  }

  h3 {
    @include relative-font-size(1.625);

    @include media-query($on-laptop) {
      @include relative-font-size(1.375);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
  }
}
