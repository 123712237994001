/**
 * Site header
 */

.site-header {
  // border-top: 0px solid $grey-color-dark;
  border-top: 2px solid $blue-color;
  //border-bottom: 1px solid $grey-color-light;
  border-bottom: 0px solid $blue-color-dark;
  background-color: $head-background-color;
  // -webkit-box-shadow: 0 0 20px 0 $blue-color-dark;
  // box-shadow: 0 0 20px 0 $blue-color-dark;
  min-height: $spacing-unit * 1.865;

  // Positioning context for the mobile navigation icon
  position: relative;
}

.site-title {
  @include relative-font-size(1.625);
  font-weight: 300;
  line-height: $base-line-height * $base-font-size * 2.25;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;
  //text-align: center;

  &,
  &:visited {
    // color: $grey-color-dark;
    color: $grey-color-light;
  }

  @include media-query($on-palm) {
    float: right !important;
  }
}

@mixin line {
  display: inline-block;
  width: $navline-size;
  height: calc($navline-size/16);
  // background-color: rgba(0,255,0,.25);
  // background-image: radial-gradient(yellow 10%, rgba(0,0,0,.25) 10%, rgba(0,0,0,.25) 50%, transparent 50%);
  // background-repeat: no-repeat;
  // background-size: $navicon-size/8 $navicon-size/8;
  // user-select: none;
  background: $navicon-color;
  border-radius: calc($navline-size/16); 
  // color: green;
  transition: $navicon-transition;
  //box-shadow: inset 0 0 0 .125rem;
  //box-shadow: inset 0 0 0 $navicon-size/8;
}

.site-nav {
  float: right;
  line-height: $base-line-height * $base-font-size * 2.25;

  .nav-trigger {
      display: none;
  }

  .menu-icon {
    display: none;
  }

  .navicon-button {
    display: none;
  }

  .page-link {
    // color: $text-color;
    color: white;
    line-height: $base-line-height;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @include media-query($on-palm) {
    position: absolute;
    top: 9px;
    //right: $spacing-unit / 2;
    left: calc($spacing-unit / 2);
    // background-color: $background-color;
    background-color: $head-background-color;
    // border: 1px solid $grey-color-light;
    //border: 1px solid white;
    border-radius: 5px;
    //text-align: right;
    //opacity: 50%;
    text-align: left;

    label[for="nav-trigger"] {
      display: block;
      // float: right;
      float: left;
      width: 36px;
      height: 36px;
      z-index: 2;
      cursor: pointer;
    }

    .menu-icon {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 10px;
      text-align: center;

      // > svg path {
      //   fill: $grey-color-dark;
      // }

      > svg {
        path {
          fill: $grey-color-light;
        }
        path[icon-top] {
          fill: red;
        }
        path[icon-middle] {
          fill: green; 
        }
        path[icon-buttom] {
          fill: blue;
        }
      }
    }
    //
    .navicon-button {
      display: inline-block;
      padding: calc($navline-size/2) calc($navline-size/4);
      //padding: 0;
      // //width: $navicon-size/1.6;
      // //height: $navicon-size/1.8;
      transition: $navicon-transition;
      // cursor: pointer;
      // user-select: none;
      // background: none;
      float: left;

      //display: inline-block;
      // float: left;
      // width: 24px;
      // height: 12px;
      line-height: 0;
      // //padding-top: 10px;
      // //text-align: center;
      //margin: 0;
      //border: none;
      // background-color: none;
      cursor: pointer;
      user-select: none;
      bord-radus: calc($navline-size/8);
      
      &:hover {
        opacity: 1;
        
        // .navicon:before { top: -($navicon-size/4); }
        // .navicon:after { top: $navicon-size/4; }
      }

      &:acitve {
        transition: 0;
      }
    }

    .navicon {
      @include line;
      position: relative;
        
      &:before, &:after {
        @include line;
        position: absolute;
        left: 0;
        content: '';
        -webkit-transform-origin: calc($navicon-size/3) center;
                transform-origin: calc($navicon-size/3) center;
        // -webkit-transform-origin: 50% 50%;
        //         transform-origin: 50% 50%;
      }
      &:before {
        top: - math.div($navline-size, 4);
        color: $navicon-color-before;
        background-color: $navicon-color-before;
      }
      &:after {
        top: calc($navline-size/4);
        color: $navicon-color-after;
        background-color: $navicon-color-after;
      }
    }

    .navicon-button:hover{
      .navicon {
        &:before {
          top: - calc($navline-size/4);
        }
        &:after {
          top: calc($navline-size/4);
        }
      }
    }


    .navicon-button.open {
      // -webkit-transform: scale3d(1,1,1);
      //         transform: scale3d(1,1,1);
      
      .navicon {
          //color: yellow;
        background: transparent;
        //opacity: 0;

        &:before, &:after {
         top: 0;
         width: $navline-size;
        }
      
        &:before {
          -webkit-transform: rotate3d(0,0,1,-45deg);
                  transform: rotate3d(0,0,1,-45deg);
        }
        &:after {
          -webkit-transform: rotate3d(0,0,1,45deg);
                  transform: rotate3d(0,0,1,45deg);
        }
     }
    }
    //

    input ~ .trigger {
      clear: both;
      display: none;
    }

    input:checked ~ .trigger {
      display: block;
      padding-bottom: 5px;
    }

    .page-link {
      display: block;
      padding: 5px 10px;
      border-bottom: 1px solid $grey-color-dark;

      &:not(:last-child) {
        margin-right: 0;
      }
      //margin-left: 20px;
      margin-left: 0;
    }
  }
}
