/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
}

.page-heading {
  @include relative-font-size(1.25);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}

// pagination
.pagination a, .pagination span {
    padding: 7px 18px;
    border: 1px solid #eee;
    margin-left: -2px;
    margin-right: -2px;
    background-color: #ffffff;
    display: inline-block;
  }

.pagination a {    
    &:hover {
        background-color: #f1f1f1;
        color: #333;
    }
 }

.pagination {
    text-align: center;
 }

.next {
    float: right;
}